import api from '@/services/apis.js'

const state = () => ({
  reports: [],
  currentReport: {}
})

const getters = {
  getAllReports: state => state.reports,
  getCurrentReport: state => state.currentReport
}

const actions = {
  async getAllReports({commit}, payload) {
    let res = await api.reports(payload.type, payload.page)
    if(res.data.status === 200) {
      if(res.data.current_page == 1) {
        commit('setReports', res.data.data)
      }
      return res.data
    }
  },
  async getReportById({commit}, id) {
    let res = await api.reports_by_id(id)
    if(res.data.status === 200) {
      commit('setCurrentReport', res.data.data)
      return res.data.data
    }
  },
  async syncReports({state, commit}, data) {
    let reports = state.reports
    for(let i=0;i<data.length;i++) {
      reports.push(data[i])
    }
    commit('setReports', reports)
  }
}

const mutations = {
  setReports(state, data) {
    state.reports = data
  },
  setCurrentReport(state, data) {
    state.currentReport = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}