import api from '@/services/apis.js'

const state = () => ({
  travel_lists: [],
  unStartedTours:[],
  onGoingTours: [],
  finishedTours: [],
  canceledTours: [],
  current_travel_list_info: {
    travel_list_nodes: [],
    tourists: [],
    travel_plan: {
      plan_name: ""
    }
  },
  current_travel_list_nodes: [],
  current_travel_checkins: [],
  current_tourist_archive_info: {}
})

const getters = {
  allTours: state => state.travel_lists,
  onGoingTours: state => state.onGoingTours,
  unStartedTours: state => state.unStartedTours,
  finishedTours: state => state.finishedTours,
  canceledTours: state => state.canceledTours,
  currentTouristArchiveInfo: state => state.current_tourist_archive_info,
  currentTravelList: state => {
    return state.current_travel_list_info
  },
  currentTravelListNodes: state => {
    return state.current_travel_list_nodes
  },
  currentTravelCheckins: state => {
    return state.current_travel_checkins
  }
}

const actions = {
  async getAllTravelLists({ commit }, status, page=1) {
    let res = await api.get_travel_lists(status, page)
    if (res.data.status === 200 && page == 1) {
      switch(status) {
        case 0: 
          commit('setUnStartedTours', res.data.data)
          break
        case 1: 
          commit('setOnGoingTours', res.data.data)
          break
        case 2: 
          commit('setFinishedTours', res.data.data)
          break
        case 3: 
          commit('setCanceledTours', res.data.data)
          break
      }
      return res.data
    }
  },
  async getTravelListInfoById({ commit }, id) {
    let res = await api.get_travel_lists_info(id)
    if (res.data.status === 200) {
      let list = res.data.data
      for(let i=0;i<list.tourists.length;i++) {
        let tourist = list.tourists[i]
        if(tourist.id_card != null) {
          tourist.id_card = 
            tourist.id_card.substring(0, tourist.id_card.length - 12) + "********" + 
            tourist.id_card.substring(tourist.id_card.length - 4)
        } else {
          tourist.id_card = '暂无'
        }
      }
      for(let i=0;i<list.travel_list_nodes.length;i++){
        let node = list.travel_list_nodes[i]
        node.start_time = node.start_time != null?node.start_time.slice(0, 16):""
        node.end_time = node.end_time != null? node.end_time.slice(0, 16): ""
      }
      commit('setCurrentTravelListInfo', res.data.data)
      return res.data
    }
  },
  async getTravelListNodes({ commit }, travel_list_id) {
    let res = await api.get_travel_lists_nodes(travel_list_id)
    if (res.data.status === 200) {
      commit('setCurrentTravelListNodes', res.data.nodes)
    }
  },
  async getTravelCheckins({ commit }, travel_list_id) {
    let res = await api.get_travel_lists_node_checkins(travel_list_id)
    if (res.data.status === 200) {
      commit('setTravelCheckins', res.data.data)
      return res.data
    }
  },
  async getTouristArchiveInfo({commit}, params) {
    let res = await api.archive_info(params)
    if(res.data.status === 200) {
      commit('setCurrentTouristArchiveInfo', res.data.data)
      return res.data.data
    }
  },
  async syncTravelList({state, commit}, data, type) {
    let lists = []
    switch(type) {
      case 0: lists = state.unStartedTours; break;
      case 2: lists = state.finishedTours; break;
      case 3: lists = state.canceledTours; break;
    }
    for(let i=0;i<data.length;i++){
    const item = data[i]
        lists.push(item)
    }
    switch(type) {
      case 0: commit('setUnStartedTours', lists); break;
      case 2: commit('setFinishedTours', lists); break;
      case 3: commit('setCanceledTours', lists); break;
    }
  },
  async resetState({ commit }) {
    commit('resetState')
  }
}

const mutations = {
  setAllTravelLists(state, travel_lists) {
    state.travel_lists = travel_lists
  },
  setUnStartedTours(state, travel_lists) {
    state.unStartedTours = travel_lists
  },
  setOnGoingTours(state, travel_lists) {
    state.onGoingTours = travel_lists
  },
  setFinishedTours(state, travel_lists) {
    state.finishedTours = travel_lists
  },
  setCanceledTours(state, travel_lists) {
    state.canceledTours = travel_lists
  },
  setCurrentTravelListInfo(state, info) {
    state.current_travel_list_info = info
  },
  setCurrentTravelListNodes(state, nodes) {
    state.current_travel_list_nodes = nodes
  },
  setTravelCheckins(state, checkins) {
    state.current_travel_checkins = checkins
  },
  setCurrentTouristArchiveInfo(state, info) {
    state.current_tourist_archive_info = info
  },
  resetState(state) {
    state.travel_lists = []
    state.current_travel_list_info = {}
    state.current_travel_list_nodes = [],
    state.current_travel_checkins = [],
    state.unStartedTours = [],
    state.onGoingTours = [],
    state.finishedTours = [],
    state.canceledTours = []
  }
}

const namespaced = true

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
}