<template>
  <div id="xiaoxi">
    <router-view></router-view>
    <Affiliated></Affiliated>
  </div>
</template>

<script>
import Affiliated from "@/views/components/Affiliated"
export default {
  name: "xiaoxi",
  components:{
    Affiliated
  },
  mounted() {
    this.$store.dispatch("updatePageHead", "通知")
  },
  methods: {
  }
};
</script>

<style>
#xiaoxi {
  height: 100vh;
}

</style>
