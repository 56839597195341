import axios from 'axios' // 引入axios
import qs from 'qs'
import { Toast } from 'vant';
import cookies from "../utils/cookies";
import { baseBackEndUrl } from "@/utils/constants"

// 创建axios实例
const service = axios.create({ // 对axios进行基础匹配
    baseURL: `${baseBackEndUrl}guider/h5/v1/`,//测试环境
    // baseURL: 'https://www.wenlvanquan.com:9292/guider/h5/v1/', //测试环境
    timeout: 5000,
    withCredentials: false,
    validateStatus: function (status) {

        if (status == 401) {
            Toast('登录过期');
            // alert(0)
            window.location.href = '/login'
        }

        return status >= 200 && status < 300 // 默认的
    }
})

// 设置请求拦截器

// const token = localStorage.getItem('access_token') 

// alert(token)
service.interceptors.request.use(config => {
    config.headers = {
        // 'token':token,
        'Content-Type': 'application/x-www-form-urlencoded',
        'crossDomain': false,
    }
    if (cookies.get('token')) {
        config.headers['Authorization'] = cookies.get('token')
    }
    config.data = qs.stringify(config.data)
    // Toast.loading({
    //     forbidClick: true,
    //     loadingType: 'spinner',
    // });
    return config
}, error => {
    Toast.fail('请求失败!')
    return Promise.reject(error)
})
// 设置响应拦截器
service.interceptors.response.use(data => { // 响应成功关闭loading
    if (data.data === null) {
        return null;
    }

    if (data) {
        const method = data.config.method
        if (data.data.status != 200) {
            Toast(data.data.message)
        } else if (data.data.status === 200 && (method === 'delete' || method === 'post' || method === 'put')) {
            Toast.success({
                message: '操作成功',
                duration: 3000
            })
        }
        return data
    }

}, error => {
    if (error.response.data.error !== undefined) {
        Toast(error.response.data.error)
    }
    if (error.response.data.message !== undefined) {
        Toast(error.response.data.message)
    }
    return Promise.reject(error)
})

export default service