import api from '@/services/apis.js'

const state = () => ({
  notifications: [],
  currentNotification: {}
})

const getters = {
  getAllNotifications: state => state.notifications,
  getCurrentNotification: state => state.currentNotification
}

const actions = {
  async getAllNotificationsByType({ commit }, payload) {
    let res = await api.notifications(payload.type, payload.page)
    if (res.data.status === 200) {
      if (res.data.current_page == 1) {
        commit('setNotifications', res.data.data)
      }
      return res.data
    }
  },
  async getNotificationById({ commit }, id) {
    let res = await api.notifications_show(id)
    if (res.data.status === 200) {
      commit('setCurrentNotification', res.data.notification)
    }
  },
  async syncNotifications({ state, commit }, data) {
    let notifications = state.notifications
    for (let i = 0; i < data.length; i++) {
      notifications.push(data[i])
    }
    commit('setNotifications', notifications)
  }
}

const mutations = {
  setNotifications(state, data) {
    state.notifications = data
  },
  setCurrentNotification(state, data) {
    state.currentNotification = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}