import page from './modules/page'
import travel_list from './modules/travel_list'
import events from './modules/events'
import xiaoxi from './modules/xiaoxi'
import wode from './modules/wode'
import report from './modules/report'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    page,
    travel_list,
    events,
    xiaoxi,
    wode,
    report
  }
})