import Vue from 'vue'
import VueRouter from 'vue-router'
import Gongzuotai from '../views/Gongzuotai.vue'
import Xiaoxi from '@/views/xiaoxi/index'
import Wode from '../views/wode/index.vue'
import cookies from "../utils/cookies";
// import store from '../store/index'
const Login = () =>
    import('../views/Login/index.vue')
const Information = () =>
    import('../views/Information/index.vue')
Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/home',
    component: () =>
        import('@/layout/index'),
    meta: { auth: true, name: '我的', isImersion: true, color: '#000' },
    children: [{
        path: 'home',
        meta: { auth: true, name: '首页' },
        component: () =>
            import('@/views/Home')
    },

    {
        path: 'wode',
        meta: { auth: true, name: '我的' },
        redirect: { name: 'wodeMain' },
        component: Wode,
        children: [{
            path: "edit",
            name: "wodeEdit",
            meta: { auth: true, name: '个人信息修改' },
            component: () =>
                import('@/views/wode/edit')
        },
        {
            path: "main",
            name: "wodeMain",
            meta: { auth: true, name: '个人信息主页' },
            component: () =>
                import('@/views/wode/main')
        },
        {
            path: "company/:id",
            name: "wodeCompany",
            meta: { auth: true, name: '旅行社信息' },
            component: () =>
                import('@/views/wode/company.info')
        },
        {
            path: "changePassword",
            name: "wodeChangePassword",
            meta: { auth: true, name: '修改密码' },
            component: () =>
                import('@/views/wode/change.password')
        },
        {
            path: "tempCompanies",
            name: "wodeTempCompanies",
            meta: { auth: true, name: '借调公司' },
            component: () =>
                import('@/views/wode/temp.companies')
        }
        ]
    },
    {
        path: 'xiaoxi',
        name: 'Xiaoxi',
        meta: { auth: true, name: '消息' },
        component: Xiaoxi,
        redirect: { name: 'xiaoxiMain' },
        children: [{
            path: 'info/:id',
            component: () =>
                import('@/views/xiaoxi/info'),
            name: 'xiaoxiInfo',
            meta: { auth: true, name: '消息详情' },
        },
        {
            path: 'main',
            component: () =>
                import('@/views/xiaoxi/main'),
            name: 'xiaoxiMain',
            meta: { auth: true, name: '消息主页' },
        },
        {
            path: 'jiangbuList',
            component: () =>
                import('@/views/xiaoxi/jiangbuList'),
            name: 'jiangbuList',
            meta: { auth: true, name: '可申请的奖补' },
        },
        {
            path: 'jiangbuinfo',
            component: () =>
                import('@/views/xiaoxi/jiangbuinfo'),
            name: 'jiangbuinfo',
            meta: { auth: true, name: '奖补详情' },
        },

        {
            path: 'report/:id',
            component: () =>
                import('@/views/xiaoxi/report'),
            name: 'xiaoxiReport',
            meta: { auth: true, name: '消息详情' },
        },
        {
            path: 'editReport',
            component: () =>
                import('@/views/xiaoxi/report.edit.vue'),
            name: 'xiaoxiReportEdit',
            meta: { auth: true, name: '消息详情' },
        },
        {
            path: 'reportLists',
            component: () =>
                import('@/views/xiaoxi/report.lists.vue'),
            name: 'xiaoxiReportLists',
            meta: { auth: true, name: '报告信息详情' },
        }
        ]
    },
    {
        path: 'events',
        name: 'events',
        meta: { auth: true, name: '消息' },
        redirect: { name: "eventMain" },
        component: () =>
            import("@/views/events/index"),
        children: [{
            path: 'main',
            name: 'eventMain',
            meta: { auth: true, name: '事件主页' },
            component: () =>
                import('@/views/events/main')
        },
        {
            path: 'report',
            name: 'eventReport',
            meta: { auth: true, name: '情况上报' },
            component: () =>
                import('@/views/events/report')
        },
        {
            path: 'info/:id',
            name: 'eventInfo',
            meta: { auth: true, name: '事件详情' },
            component: () =>
                import('@/views/events/info')
        },
        ]
    },
    {
        path: 'tour',
        name: 'tour',
        meta: { auth: true, name: '出团' },
        redirect: { name: 'tourMain' },
        component: () =>
            import('@/views/tour/index'),
        children: [{
            path: 'main',
            name: 'tourMain',
            meta: { auth: true, name: '出团主页' },
            component: () =>
                import('@/views/tour/main')
        },
        {
            path: 'info',
            name: 'tourInfo',
            meta: { auth: true, name: '出团信息' },
            component: () =>
                import('@/views/tour/info')
        },
        {
            path: 'info/:id',
            name: 'tourInfo',
            meta: { auth: true, name: '出团信息' },
            component: () =>
                import('@/views/tour/info')
        },
        {
            path: 'edit/:id',
            name: 'tourEdit',
            meta: { auth: true, name: '出团信息' },
            component: () =>
                import('@/views/tour/edit')
        },
        {
            path: 'edit',
            name: 'tourEdit',
            meta: { auth: true, name: '出团信息' },
            component: () =>
                import('@/views/tour/edit')
        },
        {
            path: 'xingcheng',
            name: 'tourXingcheng',
            meta: { auth: true, name: '出团行程' },
            component: () =>
                import('@/views/tour/xingcheng')
        },
        ]
    },
    {
        path: 'weather',
        name: 'weather',
        meta: { auth: true, name: '天气', keepAlive: true },
        component: () =>
            import('@/views/weather/index'),
    },
    {
        path: '/scan',
        name: 'scan',
        meta: { auth: true, name: '扫码', keepAlive: false },
        component: () =>
            import('@/views/scan/index'),
    },
    ]
},
// 培训管理
{
    path: '/trainManage',
    name: 'trainManage',
    meta: { auth: true, name: '培训管理' },
    component: () =>
        import('@/views/trainManage/index'),
},
{
    path: '/trainManage/item',
    name: 'trainManage-item',
    meta: { auth: true, name: '培训详情' },
    component: () =>
        import('@/views/trainManage/item'),
},
{
    path: '/scan',
    name: 'scan',
    meta: { auth: true, name: '扫码' },
    component: () =>
        import('@/views/scan/index'),
},
{
    path: '/login',
    name: 'Login',
    meta: { auth: false, name: '登录' },
    component: Login
},
{
    path: '/register',
    name: 'Register',
    meta: { auth: false, name: '注册' },
    component: () =>
        import('@/views/register/index')
},
{
    path: '/agreement',
    name: 'agreement',
    meta: { auth: true, name: '用户协议' },
    component: () =>
        import('@/views/agreementPrivacy/agreement'),
},
{
    path: '/privacy',
    name: 'privacy',
    meta: { auth: true, name: '隐私条款' },
    component: () =>
        import('@/views/agreementPrivacy/privacy'),
},
{
    path: '/gongzuotai',
    name: 'Gongzuotai',
    meta: { auth: true, name: '工作台' },
    component: Gongzuotai
},
{
    path: '/info',
    name: 'Information',
    meta: { auth: true, name: '在线申请' },
    component: Information
}
]

const router = new VueRouter({
    mode: 'history',
    routes
})
// router.beforeEach((to, from, next) => {

//     next();
// });
router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/agreement', '/privacy']
    if (publicPages.includes(to.path)) {
        cookies.remove('token')
    }
    const authRequired = !publicPages.includes(to.path)

    const loggedIn = cookies.get('token')

    if (to.meta.auth) {
        if (authRequired && !loggedIn) {
            return next('/login')
        }
    }

    next()
})

export default router