import request from '@/services/request'
const apis = {
  // ---奖补相关---
  // 我的奖补列表
  get_jiangbu_list(query) {
    return request({
      url: "/jiang_bus/my_jiang_bu_user_relations",
      method: "get",
      params: query
    })
  },
  // 可以申请的奖补列表
  get_applyjiangbu_list(query) {
    return request({
      url: "/jiang_bus/jiang_bu_user_relations",
      method: "get",
      params: query
    })
  },

  // 奖补名单
  get_jiang_bu_list(query) {
    return request({
      url: "/jiang_bus/jiang_bu_user_relations",
      method: "get",
      params: query
    })
  },
  // 奖补详情
  get_jiangbu_mes(id) {
    return request({
      url: `/jiang_bus/${id}`,
      method: "get"
    })
  },
  // 申请奖补
  apply_jiangbu(id) {
    return request({
      url: `/jiang_bus/${id}/apply`,
      method: "put"
    })
  },
  // ---培训相关---
  // 获取培训列表
  get_traings_list(query) {
    return request({
      url: "/trainings/training_user_relations",
      method: "get",
      params: query
    })
  },
  // 获取培训详情
  get_traings_mes(id) {
    return request({
      url: `/trainings/${id}`,
      method: "get"
    })
  },

  //登录
  sign_in(user) {
    return request({
      url: '/Login/login',
      method: 'post',
      data: user
    })
  },
  sms_codes(user) {
    return request({
      url: '/Login/sms_codes',
      method: 'post',
      data: { mobile: user.mobile }
    })
  },
  change_password(data) {
    return request({
      url: '/Login/change_password',
      method: 'put',
      data: data
    })
  },
  register(user) {
    return request({
      url: '/Login/regist',
      method: 'post',
      data: user
    })
  },
  login_region_data() {
    return request({
      url: '/Login/region_data',
      method: 'get'
    })
  },
  get_guide() {
    return request({
      url: '/guide',
      method: 'get',
    })
  },
  update_guide(user) {
    return request({
      url: '/guide',
      method: 'put',
      data: user
    })
  },
  guide_confirm_relate(data) {
    return request({
      url: '/guide/confirm_relate',
      method: 'post',
      data: data
    })
  },
  guide_confirm_tmp_relation(data) {
    return request({
      url: '/guide/confirm_tmp_relation',
      method: 'post',
      data: data
    })
  },
  guide_remove_guide_from_tmp_relation(id) {
    return request({
      url: `/guide/confirm_tmp_relation/${id}`,
      method: 'delete',
    })
  },
  guide_tmp_companies(status, page = 1) {
    return request({
      url: '/guide/tmp_companies',
      method: 'get',
      params: { status: status, page: page }
    })
  },
  get_travel_lists(status, page = 1) {
    return request({
      url: '/travel_lists',
      method: 'get',
      params: { status: status, page: page }
    })
  },
  get_travel_lists_from_ids(ids, page = 1) {
    return request({
      url: '/travel_lists/lists_from_ids',
      method: 'get',
      params: { ids: ids, page: page }
    })
  },
  get_travel_lists_nodes(id) {
    return request({
      url: '/travel_lists/nodes',
      method: 'get',
      params: {
        travel_list_id: id
      }
    })
  },
  get_travel_lists_info(id) {
    return request({
      url: '/travel_lists/info',
      method: 'get',
      params: {
        travel_list_id: id
      }
    })
  },
  travel_lists_checkin(checkin_data) {
    return request({
      url: '/travel_lists/checkin',
      method: 'post',
      data: checkin_data
    })
  },
  get_travel_lists_node_checkins(id) {
    return request({
      url: '/travel_lists/node_checkins',
      method: 'get',
      params: {
        travel_list_id: id
      }
    })
  },
  travel_lists_change_status(data) {
    return request({
      url: '/travel_lists/change_status',
      method: 'post',
      data: data
    })
  },
  notifications(type, page = 1) {
    return request({
      url: '/notifications',
      method: 'get',
      params: { notification_type: type, page: page }
    })
  },
  get_notifications(params) {
    return request({
      url: '/notifications',
      method: 'get',
      params: params
    })
  },
  notifications_show(id) {
    return request({
      url: '/notifications/show',
      method: 'get',
      params: { relation_id: id }
    })
  },
  emergency_reports(page = 1) {
    return request({
      url: '/emergency_reports',
      method: 'get',
      params: { page: page }
    })
  },
  emergency_reports_from_ids(ids, page = 1) {
    return request({
      url: '/emergency_reports/reports_from_ids',
      method: 'get',
      params: { ids: ids, page: page }
    })
  },
  add_emergency_reports(data) {
    return request({
      url: '/emergency_reports',
      method: 'post',
      data: { emergency: data }
    })
  },
  update_emergency_reports(data) {
    return request({
      url: `/emergency_reports/${data.id}`,
      method: 'put',
      data: data
    })
  },
  emergency_reports_by_id(id) {
    return request({
      url: `/emergency_reports/${id}`,
      method: 'get',
    })
  },
  reports(type, page = 1) {
    return request({
      url: '/reports',
      method: 'get',
      params: { report_type: type, page: page }
    })
  },
  reports_by_id(id) {
    return request({
      url: `/reports/${id}`,
      method: 'get',
    })
  },
  add_reports(data) {
    return request({
      url: '/reports',
      method: 'post',
      data: data
    })
  },
  archive_info(params) {
    return request({
      url: "/archive_infos/archive_info",
      method: 'get',
      params: params
    })
  },
  agency(id) {
    return request({
      url: "/agency",
      method: 'get',
      params: { id: id }
    })
  },
  count_info() {
    return request({
      url: "/guide/count_info",
      method: 'get'
    })
  },
  yiqing_info() {
    return request({
      url: "/ncov/ncov_info",
      method: 'get'
    })
  },
  tourists_from_ids(ids, list_ids, page = 1) {
    return request({
      url: "/tourists/tourists_from_ids",
      method: "get",
      params: { ids: ids, list_ids: list_ids, page: page }
    })
  },
  // 培训签到
  traing_sign(id, data) {
    return request({
      url: `/trainings/${id}/sign`,
      method: "post",
      data: data
    })
  },
}

export default apis