<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(179deg, #6C94F6 0%, #436EF0 16%, #3A5BE9 100%);
  height: 100vh;
  width: 100vw;
  background-size: 100%;
  position: fixed;
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    text-decoration: none;
    color: inherit;
    font-weight: normal;
    font-family: "微软雅黑";
    box-sizing: border-box;
    font-style: normal;
}

img {
    vertical-align: middle;
    max-width: 100%;
}

a:hover,
a:active,
a:visited,
a:link,
a:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    text-decoration: none;
}
.van-pull-refresh__head {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50px;
    overflow: hidden;
    color: #fff;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
</style>
