import api from '@/services/apis.js'
import cookies from "../../utils/cookies"

const defaultPersonInfoData = {
  user: {
    status: "",
  },
  guide: {
    status: "",
    guide_rate_star: 0,
    level: "",
    name: "",
    id_card: "",
    language: "",
    tourist_certificate_image: ""
  },
  agency: {
    address: "",
    agency_rate_star: "",
    area_code: "",
    business_no: "",
    city_id: "",
    created_at: "",
    deleted_at: "",
    district_id: "",
    fax: "",
    id: "",
    legal_person: "",
    license_file_no: "",
    license_no: "",
    manager: "",
    name: "",
    name_en: "",
    place_of_business: "",
    province_id: "",
    qrcode: "",
    social_credit_card: "",
    status: "",
    telephone: "",
    uniq_code: "",
    unit: "",
    updated_at: "",
    validity_end_date: "",
    validity_start_date: ''
  }
}

const state = () => ({
  personInfo: defaultPersonInfoData,
  regionData: [],
  tmp_companies_unrelated: [],
  tmp_companies_related: [],
  tmp_companies: [],
  current_agency: {}
})

const getters = {
  getPersonInfo: state => state.personInfo,
  getFmtPersonInfo: state => {
    const valid_period = new Date(state.personInfo.guide.valid_period)
    if(valid_period.getFullYear() == 2999) {
      state.personInfo.guide.valid_period = '长期'
    }
    const result = {
      mobile: state.personInfo.user.mobile,
      name: state.personInfo.guide.name,
      gender: state.personInfo.guide.gender,
      avatar: state.personInfo.user.avatar,
      id_card: state.personInfo.guide.id_card,
      role_name: state.personInfo.guide.role_name,
      tourist_card: state.personInfo.guide.tourist_card,
      tourist_card_image: state.personInfo.guide.tourist_card_image,
      tourist_certificate: state.personInfo.guide.tourist_certificate,
      tourist_certificate_image: state.personInfo.guide.tourist_certificate_image,
      establish_date: state.personInfo.guide.establish_date,
      valid_period: state.personInfo.guide.valid_period,
      level: state.personInfo.guide.level,
      language: state.personInfo.guide.language.split(',')
    }
    return result
  },
  getRegionData: state => state.regionData,
  tmpCompaniesRelated: state => state.tmpCompaniesRelated,
  tmpCompaniesUnrelated: state => state.tmpCompaniesUnrelated,
  tmpCompanies: state => state.tmpCompanies,
  currentAgency: state => state.currentAgency
}

const actions = {
  async getPersonInfo({ commit }) {
    let res = await api.get_guide()
    if (res.data.status === 200) {

      const mobile = res.data.data.user.mobile
      cookies.set('mobile', mobile)
      commit('setPersonInfo', res.data.data)
      return res.data.data
    }
  },
  async syncPersonInfo({ commit }, data) {
    commit('setPersonInfo', data)
  },
  async resetState({ commit }) {
    commit('resetState') 
  },
  async getRegionData({commit}) {
    let res = await api.login_region_data()
    if(res.data.status === 200) {
      commit('setRegionData', res.data.data)
    }
  },
  async getTmpCompanies({commit}, status, page=1) {
    let res = await api.guide_tmp_companies(status, page)
    if(res.status === 200) {
      switch(status) {
        case 0: 
          commit('setTmpCompaniesUnrelated', res.data.data)
          return res.data.data
        case 1: 
          commit('setTmpCompaniesRelated', res.data.data)
          return res.data.data
        default: 
          commit('setTmpCompanies', res.data.data)
          return res.data

      }
    }
  },
  async getAgency({commit}, id) {
    let res = await api.agency(id)
    if(res.status === 200) {
      commit('setCurrentAgency', res.data.data)
      return res.data
    }
  }
}

const mutations = {
  setPersonInfo(state, data) {
    state.personInfo = data
  },
  resetState(state) {
    state.personInfo = defaultPersonInfoData
  },
  setRegionData(state, data) {
    state.regionData = data
  },
  setTmpCompaniesUnrelated(state, data) {
    state.tmp_companies_unrelated = data
  },
  setTmpCompaniesRelated(state, data) {
    state.tmp_companies_related = data
  },
  setTmpCompanies(state, data) {
    state.tmp_companies = data
  },
  setCurrentAgency(state, data) {
    state.current_agency = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}