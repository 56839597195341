import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import apis from '@/services/apis'
import store from './store/index'
import "@/svgIcon/index.js"
import "./assets/less/vant.less"
import 'vant/lib/index.less';
// import VueAMap from "vue-amap";

// setTimeout(() => {

//         Vue.use(VueAMap);
//         // 初始化vue-amap
//         VueAMap.initAMapApiLoader({
//             key: "74bfabca263c5d85fc9d7459d275457e", // 这里写你申请的高德地图的key
//             plugin: ["AMap.Autocomplete", "AMap.Geocoder", "AMap.Geolocation"],
//             v: "1.4.15",
//             uiVersion: "1.1"
//         });
//     }, 10)
// import AmapVue from '@amap/amap-vue';
// AmapVue.config.key = '74bfabca263c5d85fc9d7459d275457e'
// AmapVue.config.plugins = ['Amap.Geolocation', 'Amap.Geocoder']
//     // Vue.use(AmapVue)
// setTimeout(() => {

//     localStorage.clear()
//     Vue.use(AmapVue)
// }, 10)

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$apis = apis;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')