<template>
  <div id="wode">
    <router-view></router-view>
    <Affiliated></Affiliated>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Affiliated from "@/views/components/Affiliated"
export default {
  name: "wode",
  components:{
    Affiliated
  },
  data() {
    return {
      //   indexList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      
    };
  },
  computed: {
    ...mapGetters({
      pageHeader: state => state.pageHeader.currentPageHead
    })
  },
  mounted() {
    this.$store.dispatch("updatePageHead",'我的')
  },
  methods: {
    sign_out(){
      this.$router.push("/login")
    }
  },
};
</script>

<style scoped>
#wode {
  height: 100vh;
}

</style>
