<template>
  <div id="home">
    <van-tabbar route>
      <van-tabbar-item
        v-for="(item, index) in list"
        :key="index"
        :icon="item.icon"
        :badge="item.badge>0?item.badge:''"
        :to="item.url"
        >{{ item.title }}</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Tarbar",
  data() {
    return {
      list: [
        {
          title: "出团",
          icon: require("../../assets/xingcheng.png"),
          url: "/tour",
          badge: 0,
        },
        {
          title: "事件",
          icon: require("../../assets/shiguchuli.png"),
          url: "/events",
          badge: 0,
        },

        {
          title: "我的",
          icon: require("../../assets/wode.png"),
          url: "/",
          badge: 0,
        },
      ],
    };
  },
  components: {},
  methods: {},
};
</script>

<style>
</style>
