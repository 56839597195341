const state = () => ({
  currentPageHead: ""
})

const getters = {
  getPageHead: state => state.currentPageHead
}

const actions = {
  updatePageHead(state, head) {
    state.commit("updatePageHead", head)
  }
}

const mutations = {
  updatePageHead(state, header) {
    state.currentPageHead = header
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}