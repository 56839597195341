<template>
  <div id="gongzuotai">
    <van-nav-bar title="工作台">
      <template #right> </template>
    </van-nav-bar>
    <!-- <div>
      <img style="width:100%" src="../assets/image/text-bck.png" alt="">
    </div> -->
    <div class="grid-wrapper">
      <van-grid square :column-num="3" :gutter="10" clickable>
        <van-grid-item
          v-for="(value,index) in list"
          :key="index"
          :icon="value.icon"
          :text="value.name"
          :url="value.url"
        />
      </van-grid>
    </div>

    <Tarbar></Tarbar>
  </div>
</template>

<script>
import Tarbar from "./components/Tarbar.vue";
export default {
  name: "Tongxun",
  data() {
    return {
        list: [
           
            {
                name:"行程报备",
                icon:require("../assets/xingcheng.png"),
                 url:'/info'
            },
            {
                name:"投诉处理",
                icon:require("../assets/yijian.png"),
                 url:'/info'
            },
             {
                name:"培训",
                icon:require('../assets/peixun.png'),
                 url:'/info'
            },
            {
                name:"打卡",
                icon:require('../assets/daka.png'),
                 url:'/info'
            },
            
            {
                name:"资料",
                icon:require("../assets/ziliao.png"),
                url:'/info'
            },
            
        ]
    };
  },
  methods: {},
  components: {
    Tarbar,
  },
};
</script>

<style>
#gongzuotai {
  height: 100vh;
}
.grid-wrapper{
  margin-top:.5rem;
}
</style>
