<template>
  <div>
    <van-overlay z-index="1000" :show.sync="showCompanyInfoConfirm" @click="showCompanyInfoConfirm = false" >
      <van-row justify="center">
        <card style="margin-top:40%;width:90%;margin-left:auto;margin-right:auto">
          <template #content>
            <div style="text-align:center">
              <van-row style="font-size: 20px">
                <span>
                  当前待挂靠的公司信息
                </span>
              </van-row>
              <van-row style="margin-top: 30px;font-size:20px">
                <span>
                  公司名称：{{user.agency == null? '无':user.agency.name}}
                </span>
              </van-row>
              <van-row style="font-size:20px;line-height:.2rem">
                <span>
                  是否决定挂靠？
                </span>
              </van-row>
              <van-row style="margin-top:50px">
                <van-col span="12">
                  <van-button type="default" @click="doCompanyInfoConfirm(false)" round style="width:90%">不同意</van-button>
                </van-col>
                <van-col span="12">
                  <van-button type="info" @click="doCompanyInfoConfirm(true)" round style="width:90%">同意</van-button>
                </van-col>
              </van-row>
            </div>
          </template>
        </card>
      </van-row>
    </van-overlay> 
    <van-overlay z-index="1000" :show.sync="showTmpCompaniesConfirm" @click="showTmpCompaniesConfirm = false" >
      <van-row justify="center">
        <card style="margin-top:40%;width:90%;margin-left:auto;margin-right:auto">
          <template #content>
            <div style="text-align:center">
              <van-row style="font-size: 20px">
                <span>
                  当前待借调的公司信息
                </span>
              </van-row>
              <van-row style="margin-top: 30px;font-size:20px" class="row_title">
                <van-col span="14">
                  <span> 公司名称 </span>
                </van-col>
                <van-col span="10">
                  <span> 是否借调 </span>
                </van-col>
              </van-row>
              <van-row v-for="(item, index) in unrelatedTmpCompanies" :key="index"
                class="row">
                <van-col span="14">
                  <div>
                    <span>
                      {{item.travel_company.name}}
                    </span>
                  </div>
                </van-col>
                <van-col span="10" style="line-height:.8rem">
                  <van-button size="small" round type="info" color="#4168EE"
                    style="height:.4rem" 
                    @click="doConfirmTmpCompany(item.relation.id, 1)">
                    <span style="color:#fff"> 同意 </span>
                  </van-button>
                  <van-button size="small" round type="info" color="#ADADAD25"
                    style="height:.4rem;margin-left:.1rem"
                    @click="doConfirmTmpCompany(item.relation.id, 2)">
                    <span style="color:#C1C1C1">拒绝</span>
                  </van-button>
                </van-col>
              </van-row>
            </div>
          </template>
        </card>
      </van-row>
    </van-overlay> 
  </div>
</template>

<script>
import card from "@/views/components/Card";
import {mapGetters} from "vuex"
import api from "@/services/apis.js"
export default {
  name: 'Affiliated',
  components:{
    card
  },
  data() {
    return {
      showCompanyInfoConfirm:false,
      showTmpCompaniesConfirm: false,
      unrelatedTmpCompanies: []
    }
  },
  computed: {
     ...mapGetters('wode', {
      user: 'getPersonInfo',
      fuser: 'getFmtPersonInfo',
      relatedTmpCompanies: 'tmpCompaniesRelated',
    })
  },
  created(){
    const _self = this
    setTimeout(function(){
      if(_self.user.guide.status == 4 && _self.user.agency != null) {
        _self.showCompanyInfoConfirm = true
      } else {
        _self.$store.dispatch('wode/getTmpCompanies', 0).then((res) => {
          if(res.length > 0) {
            _self.unrelatedTmpCompanies = res
            _self.showTmpCompaniesConfirm = true
          }
        })
        _self.$store.dispatch('wode/getTmpCompanies', 1)
      }
    },100)
  },
  methods: {
    doCompanyInfoConfirm(agreeFlag) {
      const _self = this
      const data = {
        id: this.user.guide.id,
        status: agreeFlag
      }
      api.guide_confirm_relate(data).then((res) => {
        if(res.data.status === 200) {
          _self.showCompanyInfoConfirm = false
          this.$store.dispatch('wode/getPersonInfo')
        }
      })
    },
    doConfirmTmpCompany(id, status) {
      const _self = this
      api.guide_confirm_tmp_relation({
        relation_id: id,
        status: status
      }).then((res) => {
        if(res.status === 200) {
          _self.$store.dispatch('wode/getTmpCompanies', 0)
          _self.$store.dispatch('wode/getTmpCompanies', 1)
          if(_self.unrelatedTmpCompanies.length === 0) {
            setTimeout(() => {
              _self.showTmpCompaniesConfirm = false
            }, 2000);
          }
        }
      })
    }
  },
}
</script>

<style scoped>
.row_title span{
  font-size: .3rem;
  font-weight: 600;
}
.row span{
  font-size: .3rem;
  color: #666666;
}
</style>
