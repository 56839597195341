import api from '@/services/apis.js'

const state = () => ({
  events: [],
  currentEvent: {}
})

const getters = {
  getAllEvents: state => state.events,
  getCurrentEvent: state => state.currentEvent
}

const actions = {
  async getAllEvents({commit}, page=1) {
    let res = await api.emergency_reports()
    if(res.data.status === 200) {
      let events = res.data.data
      for(let i=0;i<events.length;i++) {
        const event = events[i]
        event["fmt_date"] = getFormatDate(event.created_at);
      }
      if(page == 1) {
        commit('setEvents', events)
      }
      return res.data
    }
  },
  async getCurrentEvent({commit}, id) {
    const res = await api.emergency_reports_by_id(id)
    if(res.data.status === 200) {
      let event = res.data.data
      const tourRes = await api.get_travel_lists_info(event.travel_list_id)
      event['travel_list'] = tourRes.data.data
      event["fmt_date"] = getFormatDate(event.created_at);
      commit('setCurrentEvent', event)
      return res.data
    }
  },
  async syncEvents({state, commit}, data) {
    let events = state.events
    for(let i=0;i<data.length;i++) {
      events.push(data[i])
    }
    commit('setEvents', events)
  }
}

function getFormatDate(dateStr) {
  const date = new Date(dateStr);
  return `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
}

const mutations = {
  setEvents(state, events) {
    state.events = events
  },
  setCurrentEvent(state, event) {
    state.currentEvent = event
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}